import { useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { ISelectOptions } from "../../../types/inputs";
import { useContextSite } from "../../../context/Context";
import { Gerenciamento } from "../../../services/Gerenciamento";
import { MesesDoAnoEnum } from "../../../enums/mesesEnum";
import {
  IPesquisaProps,
  IPesquisaSatisfacaoPrincipalDTO,
} from "../../../types/pesquisa";
import { Pesquisa } from "../../../services/Pesquisa";

const mothOptions: ISelectOptions[] = Object.entries(MesesDoAnoEnum)
  .filter((i) => !isNaN(parseInt(i[0])))
  .map((v) => ({
    value: v[0],
    label: v[1] as string,
  }));
export const useCustomerSatisfaction = () => {
  const empresa = process.env.REACT_APP_PROJECT;
  const [unidadesOptions, setUnidadesOptions] = useState<ISelectOptions[]>(
    [] as ISelectOptions[]
  );
  const { setIsLoad } = useContextSite();
  const currentYear = new Date().getFullYear();
  const currentMoth = new Date().getMonth() + 1;
  const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => {
    const year = 2020 + index;
    return { value: year, label: year.toString() };
  });
  const [filter, setFilter] = useState<IPesquisaProps>({
    ano: currentYear,
    mes: currentMoth,
    empresa,
    unidade: "",
  });
  const [pesquisa, setPesquisa] = useState<IPesquisaSatisfacaoPrincipalDTO[]>(
    [] as IPesquisaSatisfacaoPrincipalDTO[]
  );

  function getPesquisa() {
    setIsLoad(true);
    Pesquisa.resultadoPesquisa(filter)
      .then(({ data }) => {
        setPesquisa(data);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  }

  const getUnidades = useCallback(() => {
    setIsLoad(true);
    Gerenciamento.listarUnidades({ empresa })
      .then(({ data }) => {
        const options = data.map((item) => ({
          label: item.cidade,
          value: item.cidade,
        }));

        setUnidadesOptions(options);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  }, []);

  useEffect(() => {
    getUnidades();

    getPesquisa();
  }, [getUnidades]);

  function handleFilter(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    getPesquisa();
  }

  return {
    yearOptions,
    mothOptions,
    unidadesOptions,
    filter,
    setFilter,
    handleFilter,
    pesquisa,
  };
};

import { AxiosResponse } from "axios";

import { removeEmpty } from "../../utils/removeEmpty";
import { ApiBrave } from "../../Apis/Brave";
import {
  IPesquisaProps,
  IPesquisaSatisfacaoPrincipalDTO,
} from "../../types/pesquisa";

const basePath = "/pesquisa";

export class Pesquisa {
  static async resultadoPesquisa(
    props: IPesquisaProps
  ): Promise<AxiosResponse<IPesquisaSatisfacaoPrincipalDTO[]>> {
    const values = removeEmpty(props);
    const params = new URLSearchParams({ ...values }).toString();
    return ApiBrave.get(`${basePath}/pesquisa-satisfacao?${params}`);
  }
}

import React, { ComponentProps } from "react";
import * as S from "./styles";
import { Outlet } from "react-router-dom";
import { useLayout } from "./useLayout";
import { Menu } from "../../Molecules/Menu";

interface ILayoutProps extends ComponentProps<"div"> {
  children?: React.ReactNode;
  paddingSecond?: boolean;
  title: string;
}

export const LayoutTemplate = ({
  children,
  paddingSecond,
  title,
  ...rest
}: ILayoutProps) => {
  const {} = useLayout();
  return (
    <S.Wrapper {...rest}>
      <Menu />
      <S.Main>
        <S.Header>
          <S.WrapperHeaderContent>
            <h2>{title}</h2>
            <S.LogoBar src="/assets/img/logo-brave.png" alt="Logo empresa" />
          </S.WrapperHeaderContent>
        </S.Header>
        <S.Container>
          {children}
          <Outlet />
        </S.Container>
      </S.Main>
    </S.Wrapper>
  );
};

import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { useProductivity } from "./useProductivity";
import { InputDate } from "../../Atoms/Inputs/InputDate";
import { SimpleSelect } from "../../Atoms/Selects/SimpleSelect";
import { ISelectOptions } from "../../../types/inputs";
import { Button } from "../../Atoms/Button";

export const ProductivitySurveyorTemplate = () => {
  const {
    handleFilter,
    Controller,
    control,
    handleSubmit,
    CidadeOptions,
    dados,
    yearOptions,
    mothOptions,
    unidadesOptions,
  } = useProductivity();

  return (
    <LayoutTemplate title="Relatório de Produtividade por Vistoriador">
      <S.Container>
        <S.Form onSubmit={handleSubmit(handleFilter)}>
          <div>
            <S.Label>Mês</S.Label>
            <Controller
              name="mes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SimpleSelect
                  required
                  options={mothOptions}
                  value={
                    mothOptions.find(
                      (item: ISelectOptions) => item.value === value
                    ) || null
                  }
                  onChange={(e: ISelectOptions) => onChange(e?.value)}
                />
              )}
            />
          </div>
          <div>
            <S.Label>Ano</S.Label>
            <Controller
              name="ano"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SimpleSelect
                  required
                  options={yearOptions}
                  onChange={(e: ISelectOptions) => onChange(e?.value)}
                  value={
                    yearOptions.find(
                      (item: ISelectOptions) => item.value === value
                    ) || null
                  }
                />
              )}
            />
          </div>

          <div>
            <S.Label>Empresa</S.Label>
            <Controller
              control={control}
              name="empresa"
              render={({ field: { value, onChange } }) => (
                <SimpleSelect
                  options={CidadeOptions}
                  value={CidadeOptions.find((i) => i.value === value) || null}
                  onChange={(e: ISelectOptions) => onChange(e?.value)}
                />
              )}
            />
          </div>

          <div>
            <S.Label>Unidade</S.Label>
            <Controller
              name="cidade"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SimpleSelect
                  isClearable
                  options={unidadesOptions}
                  value={unidadesOptions.find((i) => i.value === value) || null}
                  onChange={(e: ISelectOptions) => {
                    onChange(e?.value);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Button>Filtrar</Button>
          </div>
        </S.Form>

        <S.Table>
          <S.TableHead>
            <tr>
              <td className="fixeSize" rowSpan={3}>
                Nome do Vistoriador
              </td>
              <td className="fixeSize" rowSpan={3}>
                Empresa
              </td>
              <td className="fixeSize" rowSpan={3}>
                Unidade
              </td>
              <td colSpan={3}>Loja</td>
              <td colSpan={3}>Delivery</td>
            </tr>

            <tr>
              <td className="textSmall fixeSize bg-orange">Meta</td>
              <td className="textSmall fixeSize bg-orange">Realizado</td>
              <td className="textSmall fixeSize bg-orange">Média/Dia</td>

              <td className="textSmall fixeSize bg-orange">Meta</td>
              <td className="textSmall fixeSize bg-orange">Realizado</td>
              <td className="textSmall fixeSize bg-orange">Média/Dia</td>
            </tr>
          </S.TableHead>
          <S.TableBody>
            {dados?.map((e) => (
              <tr>
                <td>{e?.nome}</td>
                <td>{e?.empresa}</td>
                <td>{e?.cidade}</td>

                <td>{e?.metaQuantidadeLoja?.toFixed(2)}</td>
                <td>{e?.qtdLoja?.toFixed(2)}</td>
                <td>{e?.mediaQtdLoja?.toFixed(2)}</td>

                <td>{e?.metaQuantidadeDelivery?.toFixed(2)}</td>
                <td>{e?.qtdDelivery?.toFixed(2)}</td>
                <td>{e?.mediaQtdDelivery?.toFixed(2)}</td>
              </tr>
            ))}
          </S.TableBody>
        </S.Table>
      </S.Container>
    </LayoutTemplate>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  padding: ${(props) => props.theme.padding.mobile} 20px;

  @media (min-width: 640px) {
    padding: 32px 20px;
  }
`;

export const FormFilterCustomerSatisfaction = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.2fr;
  gap: 16px;
  width: 100%;
  max-width: 700px;
  margin-bottom: 40px;

  > div {
    label {
      color: #2d2d2d;
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }

  > div:last-child {
    display: flex;
    align-items: end;
  }
`;

export const Wrapper = styled.div`
  > h3 {
    color: #1d1d1b;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;

  &.wrap {
    flex-wrap: wrap;
  }
`;

export const Cards = styled.div`
  min-width: 284px;

  height: 96px;
  border-radius: 4px;
  background: #f6f6f6;
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  > p {
    color: #e84e1b;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  > span {
    color: #1d1d1b;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &.active {
    background: #1d1d1b;

    > span {
      color: #fff;
    }
  }

  &.simple {
    > p {
      color: #1d1d1b;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    > span {
      display: flex;
      gap: 6px;
      width: fit-content;
    }
  }
`;

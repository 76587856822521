import { useEffect, useState } from "react";
import { z } from "zod";
import { useContextSite } from "../../../context/Context";
import {
  IProducaoVistoriadorDTO,
  IProdutividadeVistoriadorProps,
} from "../../../types/gerenciamento";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Gerenciamento } from "../../../services/Gerenciamento";
import { toast } from "react-toastify";
import { MesesDoAnoEnum } from "../../../enums/mesesDoAno";
import { ISelectOptions } from "../../../types/inputs";

const schema = z.object({
  dataIncio: z.date().nullable().optional(),
  dataFim: z.date().nullable().optional(),
  empresa: z.string().min(1).optional().or(z.literal("")),
  cidade: z.string().min(1).optional().or(z.literal("")),
});

const currentYear = new Date().getFullYear();
const currentMoth = new Date().getMonth() + 1;
const yearOptions = Array.from({ length: currentYear - 2019 }, (_, index) => {
  const year = 2020 + index;
  return { value: year, label: year.toString() };
});

const mothOptions = Object.keys(MesesDoAnoEnum)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({
    value: MesesDoAnoEnum[key as keyof typeof MesesDoAnoEnum],
    label: key,
  }));

const CidadeOptions = [
  {
    label: "Log",
    value: "Log",
  },
  {
    label: "Starcheck",
    value: "Starcheck",
  },
  {
    label: "Tokyo",
    value: "Tokyo",
  },
  {
    label: "Velox",
    value: "Velox",
  },
];

export const useProductivity = () => {
  const { setIsLoad } = useContextSite();

  const [dados, setDados] = useState<IProducaoVistoriadorDTO[]>(
    [] as IProducaoVistoriadorDTO[]
  );
  const [unidadesOptions, setUnidadesOptions] = useState<ISelectOptions[]>(
    [] as ISelectOptions[]
  );

  const { register, control, handleSubmit, getValues, watch } =
    useForm<IProdutividadeVistoriadorProps>({
      defaultValues: {
        ano: currentYear,
        mes: currentMoth,
        cidade: "",
        empresa: CidadeOptions[0].value,
      },
      mode: "all",
      resolver: zodResolver(schema),
    });

  async function getDataProdutividade(props: IProdutividadeVistoriadorProps) {
    const result = await Gerenciamento.produtividadeVistoriador(props);
    return result;
  }

  function getUnidades() {
    setIsLoad(true);
    Gerenciamento.listarUnidades({ empresa: watch("empresa") })
      .then(({ data }) => {
        const options = data.map((item) => ({
          label: item.cidade,
          value: item.cidade,
        }));

        setUnidadesOptions(options);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  }

  function handleFilter(data: IProdutividadeVistoriadorProps) {
    setIsLoad(true);

    const { ano, mes, empresa } = getValues();

    getDataProdutividade({ ano, mes, empresa, ...data })
      .then(({ data }) => {
        setDados(data);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  }

  useEffect(() => {
    const { ano, mes, empresa } = getValues();
    setIsLoad(true);

    getDataProdutividade({
      ano,
      mes,
      empresa,
    })
      .then(({ data }) => {
        setDados(data);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => toast.error(mensagem)
      )
      .finally(() => {
        setIsLoad(false);
      });
  }, []);

  useEffect(() => {
    if (watch("empresa")) {
      getUnidades();
    }
  }, [watch("empresa")]);

  return {
    handleFilter,
    register,
    Controller,
    control,
    handleSubmit,
    CidadeOptions,
    dados,
    yearOptions,
    unidadesOptions,
    mothOptions,
  };
};
